@import '../../styles/_variables';

.content {
  background-color: $white;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0px 10px 20px rgba($secondary, 0.1) !important;
}

.header {
  justify-items: center;
  align-items: center;
  border-bottom: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;

  button {
    position: absolute;
    top: 15px;
    right: 20px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include media-breakpoint-down(lg) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    h5 {
      grid-column-start: 2;
    }
    button {
      margin-left: auto;
      margin-right: -0.75rem;
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }
}

.title {
  display: inline-block;
  position: relative;
  margin-top: 30px;

  color: $primary-900;

  p {
    margin-bottom: 0px !important;
  }

  b {
    color: $primary-500;
  }

  &::after {
    content: '';
    border-bottom: 4px solid $primary-100;
    border-radius: 8px;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 80%;
  }
}

.body {
  padding: 30px 0px 25px 0px;
}

.footer {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: none;
  padding: 0;
}
